<template>
	<div class="pui-form">
		<mdocks-modals :modelName="modelName"></mdocks-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('mdocks.fieldset.dockdata')">
						<v-row dense>
							<!-- DOCKCODE -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-text-field
									:id="`dockcode-mdocks`"
									v-model="model.dockcode"
									:label="$t('mdocks.dockcode')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="50"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row>
							<!-- DESCRIPTION -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-text-field
									:id="`description-mdocks`"
									v-model="model.description"
									:label="$t('mdocks.description')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row>
							<!-- PORTCODE -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									:id="`portcode-mdocks`"
									:attach="`portcode-mdocks`"
									:label="$t('mdocks.portcode')"
									:disabled="formDisabled"
									required
									toplabel
									clearable
									modelName="mlocalports"
									v-model="model"
									reactive
									:itemsToSelect="portcodeItemsToSelect"
									:modelFormMapping="{ portcode: 'portcode' }"
									:itemValue="['portcode']"
									:itemText="(item) => `${item.portcode}-${item.portdescription}`"
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="havePermissions(['WRITE_MDOCKS'])" >
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mdocks-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'mdocks'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		},
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
	},
	computed: {
		countrycodeItemsToSelect() {
			return [{ countrycode: this.model.countrycode }];
		},
		portcodeItemsToSelect() {
			return [{ portcode: this.model.portcode }];
		},
		filterMports() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'countrycode', op: 'eq', data: 'GE' }]
					}
				]
			};
		}
	},
	created() {}
};
</script>
